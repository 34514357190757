import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Rules and Regulations</h2>
          <p>
            All entrants must agree with and abide by all rules. Read Carefully.
          </p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Entry Criteria</h3>
          <p>ALL BOATS MUST BE A CENTER CONSOLE TO FISH THE TOURNAMENT</p>
          {/*<h3 className="major">Criteria for Professional Boat</h3>*/}
          {/*<p>None </p>*/}
          {/*<h3 className="major">Criteria for Semi-Professional Boat</h3>*/}
          {/*<p>Only one paid personnel including captains, mates and anglers.</p>*/}
          <h3 className="major">Junior Angler</h3>
          <p>15 years of age or younger.</p>
          {/*<h3 className="major">Boundaries</h3>*/}
          {/*<p>*/}
          {/*  Carysfort (25&deg;13.249') to Hillsborough Inlet (26&deg;15.070'){' '}*/}
          {/*  <br />*/}
          {/*</p>*/}
          <h3 className="major">Angler Substitutions</h3>
          <p>
            Angler substitutions must be completed before lines in via telephone
          </p>
          <h3 className="major">Check Out</h3>
          <p>
            Check out will be at the end of the Northern most channel leading
            into Shake-A-Leg Miami. All boats will be cleared to leave the area
            at 7:00am by the stake boat located in the channel.
          </p>
          <h3 className="major">Check In</h3>
          <p>All boats must check in by 6:00pm at Shake-A-Leg Miami</p>
          <h3 className="major">Captain's Meeting</h3>
          <p>
            Thursday, August 25th, 2022 at 6:00 - 8:00PM <br />
          </p>
          <h3 className="major">Tournament Times</h3>
          <p>
            Lines in – Saturday, August 27th, 2022 at 8:00AM <br />
            Lines out – Saturday, August27th, 2022 at 4:00PM
          </p>
          {/*<h3 className="major">Communication</h3>*/}
          {/*<p>Fish can be called in via telephone or VHF channel 74</p>*/}
          {/*<h3 className="major">Cameras and Procedure for Yo-Yo</h3>*/}
          {/*<p>*/}
          {/*  Cameras must start with a video of the time. Accepted timepieces are*/}
          {/*  GPS, VHF or Cellular devices. Video must be continuous until the*/}
          {/*  fish in landed. The angler that hooks the fish must be the angler*/}
          {/*  that brings it to the boat. A gaff may be used to bring the fish*/}
          {/*  into the boat. A cell phone may be used as long as video is*/}
          {/*  consistent with all other rules requirements. Contact the committee*/}
          {/*  office to notify if a change of camera is being made.*/}
          {/*  <br />*/}
          {/*  <br />*/}
          {/*Hooking Fish – Each eligible fish entered in this tournament must be*/}
          {/*hooked and caught by a single angler. No person may hook a fish, or*/}
          {/*assist in hooking a fish then pass a rod to another angler. If*/}
          {/*multiple fish are hooked by a single angler, the angler may only*/}
          {/*fight one fish at a time. The release of the first fish must occur*/}
          {/*prior to fighting the second fish. The use of a rod holder to hook a*/}
          {/*fish is permitted provided that no person other than the angler who*/}
          {/*ultimately enters the fish touches the rod or reel once the fish is*/}
          {/*hooked. If the main line or double line is touched by anyone other*/}
          {/*than the angler the fish is considered disqualified. Mate can pop*/}
          {/*the ring out of the clip.*/}
          {/*</p>*/}
          {/*<h3 className="major">Release</h3>*/}
          {/*<p>*/}
          {/*  A “Release” is official once the mate touches the “Leader” material.*/}
          {/*  Tipping is not considered a release.*/}
          {/*</p>*/}
          {/*<h3 className="major">Final 15 Minutes</h3>*/}
          {/*<p>*/}
          {/*  The final 15 minutes of the tournament ALL billfish hookups must be*/}
          {/*  called in. In addition all video in the final 15 mins must start*/}
          {/*  with a time stamp at hookup to insure the fish was hooked before*/}
          {/*  lines out.*/}
          {/*</p>*/}
          {/*<h3 className="major">Catch Cards</h3>*/}
          {/*<p>*/}
          {/*  Cards must be filled out to match with the confirmation number and*/}
          {/*  time provided by the Release Committee. Incomplete or improperly*/}
          {/*  completed cards may be rejected by the tournament committee.*/}
          {/*</p>*/}

          <h3 className="major">Catch Cards and Password</h3>
          <p>
            A laminated catch card will be given at the Captain's Meeting. The
            password will be given on the morning of the tournament and must be
            used along with the catch card in the video of each catch.
          </p>

          <h3 className="major">Cameras and Procedure</h3>
          <p>
            Cameras must start with a video of the time. The video must include
            the catch card and the angler saying the password during the video
            to certify a catch. Video of the winning teams must be submitted and
            verified to be awarded. Accepted timepieces are GPS, VHF or Cellular
            devices. Video must be continuous until the fish in landed. The
            angler that hooks the fish must be the angler that brings it to the
            boat. A gaff may be used to bring the fish into the boat. A cell
            phone may be used as long as video is consistent with all other
            rules requirements.
          </p>

          <h3 className="major">Scoring</h3>
          <p>
            {/*<strong>Billfish Scoring:</strong>*/}
            {/*<br />*/}
            {/*All billfish count as 100 points. Time will determine the winner in*/}
            {/*the event of a tie. <br />*/}
            {/*<br />*/}
            <strong>Scoring:</strong>
            <br />
            <strong>
              - (60% of the Overall Pot divided as follows for Top 3 Aggregate
              Mahi:)
            </strong>
            <br />
            -- 1st Place boat- 60% & Trophy
            <br />
            -- 2nd Place boat- 30% & Trophy
            <br />
            -- 3rd Place boat- 10% & Trophy
            <br />
            <br />
            <strong>(10% of the Overall Pot) </strong>-- Largest Mahi
            <br />
            <br />
            <strong>(10% of the Overall Pot) </strong>-- Largest Tuna
            <br />
            <br />
            <strong>(10% of the Overall Pot) </strong>-- Largest Kingfish
            <br />
            <br />
            <strong>(10% of the Overall Pot) </strong>-- Largest Wahoo
            <br />
            <br />
            <strong>Calcutta Scoring:</strong>
            <br />
            In The Meat - Total weight of 3 Largest Fish (70/30 Split)
            <br />
            Meatsauce – The one largest fish by weight (70/30 Split)
          </p>
          <h3 className="major">General Information</h3>
          <p>
            Time Frame – 8:00AM – 4:00PM <br />
            Lines – 8<br />
            {/*Leaders – Max length 15’ (ft.)*/}
            <br />
            Hooks – J-Hooks or Circle hooks
            <br />
            Anglers – No Limit
            <br />
            Bait – Live or dead accepted. Chumming accepted. Lures accepted.
            <br />
            Weigh Station – The weigh station will be dockside at Shake-A-Leg
            Miami. All fish must be weighed by 6:00pm to qualify.
            <br />
            Weather – Tournament officials may cancel the tournament day if in
            their best judgment unsafe conditions exist at sea for the average
            boat entered. No refunds of any sort will be made for any cause in
            the event of such cancellation. If the day (or any portion thereof)
            is “weathered out”, the tournament results will be final based on
            the results of the official fishing time that can be completed.
            Notice of cancellation and/or resumption of fishing will be provided
            via Committee Phone. A determination permitting fishing by
            tournament officials should not be interpreted to mean that the
            tournament ensures the safety of any registered angler or occupant
            of any boat. Anglers must make their own judgments whether to fish
            under the prevailing weather conditions based on the size and
            seaworthiness of their boat, their seamanship skills, and any other
            relevant factors.
            <br />
            Entry Fees - A small percentage of the entry fees will be retained
            by the tournament for expenses including, but not limited to,
            processing fees, rentals, etc.
          </p>
          <h3 className="major">Protests</h3>
          <p>
            Any protests concerning the conduct of an angler or rules violations
            must be filed in writing with the tournament committee no later than
            the close of the fishing day on the day of the alleged violation
            along with a non refundable $300. Protests will be handled by the
            rules committee. The decisions of the rules committee are final.
          </p>
          <h3 className="major">Refunds</h3>
          <p>
            No refunds will be given for any reason including, but not limited
            to acts of nature, war, national security, Covid-19, vessel
            malfunctions or travel restrictions.
          </p>
          <h3 className="major">Ties</h3>
          <p>All ties will be broken based on next largest fish.</p>
          <h3 className="major">Hold Harmless</h3>
          <p>
            All registered anglers and non-registered occupants of their boats
            enter and participate in this tournament voluntarily at their own
            risk. In consideration for the tournament’s acceptance of his or her
            entry, each angler, for his or her heirs, executors, and
            administrators, agrees to release, hold harmless and indemnify the
            Tournament ( CCO Showdown LLC), its officers, managers, directors,
            agents, sponsors, donors, the city and county where the event is
            held, and all other persons connected directly or indirectly with
            the operation of the tournament, from and against any and all
            liabilities, claims, actions, damages, costs, fees or expenses which
            he or she may have against any above-described party, or which his
            or her captains, crew members, family members, companions or
            non-registered occupants of their boat may have, arising out of or
            in any way connected with his or her participation in this
            tournament, including any injury or death suffered by any registered
            angler, boat captains, crew members, family members, companions or
            other occupants of any boat, and including any damage to vessels and
            equipment, which occurs during or in conjunction with the event.
            Each Angler understands and agrees that this release specifically
            includes and waives any claims based on libel, slander, negligence,
            action or inaction of any above-named party.
            <br />
            Signing of the Entry Sheet – By signing the entry form, the Boat
            Owner/Team Representative agrees to follow the above tournament
            rules. All Boat Owners/Team Representatives are required to sign the
            entry form as certification that they have read and agreed to abide
            by all guidelines and the above liability release.
          </p>
          <h3 className="major">Committee</h3>
          <p>
            Brian Gonzalez
            <br />
            Alan Blanco
            <br />
            Sarah Melia
            <br />
            JC Cleare
            <br />
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
